@font-face {
    font-family: 'Nefelibata';
    src: url('../assets/fonts/Nefelibata-BrushCanvas.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Anton';
    src: url('../assets/fonts/Anton-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  